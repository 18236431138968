import { FilterBy, SortBy, SearchBy, Pagination, TableTools } from 'lib/types/tableTools'
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'
import { Beacon, BeaconType, TableState } from 'lib/types'
import { useGlobal } from './GlobalProvider'
import { ApiResponseV5, getRequestV5 } from 'models/api/rest'
import { ALL_ORGS_SELECTED, BeaconsColumnKeys } from 'lib/constants'
import * as utils from '../components/partial-pages/GatewaysPage/utils'
import { Gateway } from 'lib/types/gateways'
import { GatewaysColumnKeys } from 'lib/constants/keys/gateways'
// Define the Beacon type (modify this based on your actual data structure)

// Define the context type
type GatewaysContextType = {
  gateways: Gateway[]
  loading: boolean
  tableState: TableState<string, GatewaysColumnKeys>
  pagination: Pagination

  // Define your functions and their types here
  setGateways: React.Dispatch<React.SetStateAction<Gateway[]>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  // setTableTools: React.Dispatch<React.SetStateAction<TableTools<BeaconType | null, BeaconsColumnKeys>>>
  setTableState: React.Dispatch<React.SetStateAction<TableState<string, GatewaysColumnKeys>>>
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>
}

// Initialize
const GatewaysContext = createContext<GatewaysContextType>({
  gateways: [],
  loading: false,
  // tableTools: { pagination: { before: null, after: null } },
  tableState: {
    filterBy: utils.getFilterOptions()[0],
    sortBy: utils.getSortOptions()[0].sortBy,
    searchBy: '',
  },
  pagination: {
    currentPage: null,
  },
  setGateways: () => {},
  setLoading: () => {},
  setTableState: () => {},
  setPagination: () => {},
  // setTableTools: () => {},
})

type GatewaysProviderProps = {
  children: ReactNode
}

export const useGateways = () => {
  const context = useContext(GatewaysContext)
  if (!context) {
    throw new Error('useGateways must be used within a BeaconsProvider')
  }
  return context
}

export const GatewaysProvider: React.FC<GatewaysProviderProps> = ({ children }) => {
  const [gateways, setGateways] = useState<Gateway[]>([])
  const [loading, setLoading] = useState(false)
  const [tableState, setTableState] = useState<TableState<string, GatewaysColumnKeys>>({
    filterBy: utils.getFilterOptions()[0],
    sortBy: utils.getSortOptions()[0].sortBy,
    searchBy: '',
  })
  const [pagination, setPagination] = useState<Pagination>({ currentPage: null })

  const { selectedOrgId, refreshFlag, setRefreshFlag, setReportStatus } = useGlobal()

  // Implement functions for managing beacon data, search, sort, filter, pagination, etc.
  // For example, you can define functions like addBeacon, removeBeacon, setSearch, setSort, etc.

  const load = () => {
    setLoading(true)
    getRequestV5({
      endpoint: '/gateways',
      headers: selectedOrgId && selectedOrgId != ALL_ORGS_SELECTED ? { org_id: selectedOrgId } : {},
      params: {
        filterBy:
          tableState.filterBy != utils.getFilterOptions()[0]
            ? { field: 'health', value: tableState.filterBy.toUpperCase() }
            : undefined,
        sortBy: { field: tableState.sortBy.field, order: tableState.sortBy.order },
        searchBy: { value: tableState.searchBy },
      },
    }).then(response => {
      const data = response.data
      setGateways(data.data)
      setPagination({ ...data.meta })
      setLoading(false)
      console.log('Gateways context loaded')
    })
  }

  useEffect(() => {
    if (refreshFlag) {
      getRequestV5({
        endpoint: '/reports/status',
        headers: selectedOrgId === null || selectedOrgId === ALL_ORGS_SELECTED ? {} : { org_id: selectedOrgId },
        params: {},
      }).then(response => {
        setReportStatus(response.data.data)
      })
      load()
      setRefreshFlag(false)
    }
  }, [refreshFlag])

  // // Update the gateways whenever one of these params change. Pagination will be handled separately.
  useEffect(() => {
    load()
  }, [selectedOrgId])

  return (
    <GatewaysContext.Provider
      value={{
        gateways,
        setGateways,
        loading,
        setLoading,
        pagination,
        setPagination,
        tableState,
        setTableState,
        // tableTools,
        // setTableTools,
        /* functions */
      }}
    >
      {children}
    </GatewaysContext.Provider>
  )
}
