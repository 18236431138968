export const NOT_AUTHORIZED_PATH = `/not-authorized`
export const EMAIL_ALERTS_CONFIRMATION = '/confirmation-email-alerts'
export const TFA_PATH = '/2fa'

export const DASHBOARD_PATH = '/dashboard'
export const DASHBOARD_MAIN_PATH = `${DASHBOARD_PATH}/main`

export const BEACONS_PATH = `${DASHBOARD_PATH}/beacons`
export const PATH_BEACONS = `${BEACONS_PATH}/:id?`

export const DEVICES_PATH = `${DASHBOARD_PATH}/devices`
export const PATH_DEVICES = `${DEVICES_PATH}/:id?`

export const RESPONDERS_PATH = `${DASHBOARD_PATH}/responders`
export const PATH_RESPONDERS = `${RESPONDERS_PATH}/:id?`

export const USERS_PATH = `${DASHBOARD_PATH}/users`
export const PATH_USERS = `${USERS_PATH}/:id?`

export const GATEWAYS_PATH = `${DASHBOARD_PATH}/gateways`
export const PATH_GATEWAYS = `${GATEWAYS_PATH}/:id?`

export const ADMIN_PATH = `${DASHBOARD_PATH}/admin`
export const PATH_ADMIN = {
  PROPERTIES: `${ADMIN_PATH}/properties`,
  GROUPS: `${ADMIN_PATH}/groups`,
  FIRMWARE: `${ADMIN_PATH}/firmware`,
}
export const PATH_ADMIN_ALL = `${DASHBOARD_PATH}/admin/:section?`

export const ALERTS_PATH = `${DASHBOARD_PATH}/alerts`
export const PATH_ALERTS = `${ALERTS_PATH}/:id?`
export const GENERAL_SETTINGS_PATH = `${DASHBOARD_PATH}/settings`
export const BEACONS_INSTALLATION_PATH = `${DASHBOARD_PATH}/beacons-installation`
export const MOBILE_APP_PATH = '/mobile-app'
export const MOBILE_APP_ENABLE = `${MOBILE_APP_PATH}/enable_new_app`
export const SEGMENT_RESET_PASSWORD = `reset_password`
export const SEGMENT_CONFIRM_EMAIL = `confirm`

export const APP_STORE_ANDROID = 'https://play.google.com/store/apps/details?id=com.reactmobile.hospitality'
export const APP_STORE_IOS = 'https://apps.apple.com/us/app/react-mobile-hospitality/id1345636690'

export const APP_STORE_ANDROID_DEV =
  'https://play.google.com/store/apps/details?id=com.reactmobile.hospitality.installer'
export const APP_STORE_IOS_DEV = 'https://beta.itunes.apple.com/v1/app/1583853918'
