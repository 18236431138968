import { getRequest, putRequest, getRequestV5 } from './rest'
import { Beacon, BeaconType, SortBy } from 'lib/types'
import { ALL_ORGS_SELECTED, API_BEACONS, API_DEFAULT_LIMIT, API_UNREGISTER_BEACON, API_V5_BEACONS } from 'lib/constants'
import { decodeJsonApiObject, getObjectsCSV } from './ApiLib'
import { Pagination, decodePagination, EMPTY_PAGINATION } from './pagination'
import { FilterBy, SearchBy } from 'lib/types/tableTools'
// import { Filters, Search, Sort } from 'lib/types/tableTools'

const DEFAULT_BEACON_PARAMS: BeaconParams = {
  disabled: false,
  firmwareStatus: true,
  limit: API_DEFAULT_LIMIT,
}

export const EMPTY_BEACONS: BeaconsWithPagination = {
  beacons: [],
  pagination: EMPTY_PAGINATION,
}

export interface BeaconsWithPagination {
  beacons: Beacon[]
  pagination: Pagination
}

export interface BeaconParams extends Record<string, any> {
  disabled?: boolean
  firmwareStatus?: boolean
  org_id?: string
  afterCursor?: string | null
  beforeCursor?: string | null
  limit?: number | string
}

// export interface BeaconParamsV5 {
//   filterBy?: FilterBy<BeaconType>
//   sort?: SortBy<'a' | 'v'>
//   search?: SearchBy
//   orgId?: string
// }
export const getBeaconsRaw = (params: BeaconParams = {}): Promise<Beacon[]> => {
  const qstr = new URLSearchParams(DEFAULT_BEACON_PARAMS)
  Object.entries(params).forEach(([key, value]: [key: string, value: any]) => {
    if (value) qstr.set(key, value.toString())
  })
  return getRequest({
    endpoint: `${API_BEACONS}?${qstr.toString()}`,
    header: { org_id: params.orgId },
  }).then(({ data }) => data)
}

export const getBeaconPage = (params: BeaconParams = {}): BeaconsWithPagination => {
  // @ts-ignore
  return (
    getBeaconsRaw(params)
      // @ts-ignore
      .then(({ data, meta }) => {
        const pagination = decodePagination(meta, data)
        return {
          beacons: data.map((d: any) => decodeJsonApiObject<Beacon>(d)),
          pagination,
        }
      })
  )
}

export const getAllBeaconsWithPagination = (
  params: BeaconParams,
  beacons: BeaconsWithPagination = { beacons: [], pagination: EMPTY_PAGINATION },
): BeaconsWithPagination => {
  // @ts-ignore
  return (
    getBeaconsRaw(params)
      // @ts-ignore
      .then(({ data, meta }) => {
        const items = beacons.beacons.concat(data)
        const pagination = decodePagination(meta, data)
        if (pagination.after) {
          return getAllBeaconsWithPagination(
            { ...params, beforeCursor: pagination.before, afterCursor: pagination.after },
            { beacons: items, pagination },
          )
        } else {
          return {
            beacons: items.map((d: any) => decodeJsonApiObject<Beacon>(d)),
            pagination,
          }
        }
      })
  )
}

export const disableBeacon = (id: string, orgId: string): Promise<[]> =>
  putRequest({
    endpoint: `${API_BEACONS}/${id}`,
    data: { beacon: { disabled: true } },
    header: { org_id: orgId },
  }).then(({ data }) => data.data)

export const unregisterBeacon = (id: string, orgId: string): Promise<[]> =>
  putRequest({
    endpoint: `${API_UNREGISTER_BEACON}/${id}`,
    header: { org_id: orgId },
  }).then(({ data }) => data.data)

export const updateBeacon = (item: Beacon, orgId: string): Promise<Beacon> => {
  const { id, updatedAt, ...beacon } = item
  return putRequest({
    endpoint: `${API_BEACONS}/${id}`,
    header: { org_id: orgId },
    data: { beacon },
  }).then(({ data }) => decodeJsonApiObject<Beacon>(data.data))
}

export const getBeaconsCSV = (orgId: string): Promise<void> => {
  return getObjectsCSV('beacons', orgId)
}

export const getV5BeaconsCSV = (orgId: string): Promise<void> => {
  return getRequest({
    endpoint: `${API_V5_BEACONS}/download`,
    header: orgId === ALL_ORGS_SELECTED ? {} : { org_id: orgId },
  })
    .then((response: any) => {
      const csvData: string = response.data

      const blob = new Blob([csvData], { type: 'text/csv' })

      const link = document.createElement('a')
      link.download = `beacons.csv`
      link.href = window.URL.createObjectURL(blob)

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
    .catch((error: Error) => {
      console.error('Error fetching CSV data:', error)
      throw error
    })
}