// *** needs room height added
const beacon_coords = {
  'F8:35:CC:74:8E:A6': { coords: [-105.13356570731779, 40.552194279240304], floorNum: 1 },
  'C2:B9:06:08:BC:19': { coords: [-105.13364070731896, 40.55217206063131], floorNum: 1 },
  'E2:ED:25:FA:15:1C': { coords: [-105.13373358989547, 40.552189520388055], floorNum: 1 },
  'F0:EA:B1:20:BB:32': { coords: [-105.1336358764969, 40.55224813906885], floorNum: 1 },
  'E8:04:56:65:7D:45': { coords: [-105.13356822247897, 40.552236636897334], floorNum: 1 },
  'E0:96:0F:B5:F2:35': { coords: [-105.13357731571347, 40.55218541414222], floorNum: 0 },
  'C1:26:54:27:B4:5C': { coords: [-105.13368749327725, 40.552245398136364], floorNum: 0 },
  'E4:B2:C7:BF:1F:E9': { coords: [-105.13373154811791, 40.55221302597942], floorNum: 0 },
  'F4:B4:67:52:FB:63': { coords: [-105.13372419080135, 40.552196390693744], floorNum: 0 },
  'C6:FD:10:8C:C1:13': { coords: [-105.13362105816623, 40.552235560498374], floorNum: 0 },
  'DD:C4:5B:48:85:F7': { coords: [-105.13357035649099, 40.552125146794], floorNum: 2 },
  'C4:70:B4:51:34:CA': { coords: [-105.1335606939494, 40.552179124132714], floorNum: 2 },
  'DC:33:C8:7B:FC:F4': { coords: [-105.13360107463569, 40.55218458237247], floorNum: 2 },
  'EC:D8:35:36:BB:A0': { coords: [-105.13361460990245, 40.5522279467806], floorNum: 2 },
}

const boxTruckCoords = {
  'F8:35:CC:74:8E:A6': { coords: [-87.40063470325205, 35.497398672951704], floorNum: 1 },
  'E8:04:56:65:7D:45': { coords: [-87.40058577287749, 35.497416189490316], floorNum: 1 },
  'DD:C4:5B:48:85:F7': { coords: [-87.40067941404556, 35.49741655721198], floorNum: 2 },
  'C4:70:B4:51:34:CA': { coords: [-87.40062827958317, 35.497397864803546], floorNum: 2 },
}

const rileysCoords = {
  'D0:22:9F:77:BA:B5': { coords: [-122.31969118187307, 47.62215640493455], floorNum: 2 },
  'C5:D8:DC:8A:1D:C6': { coords: [-122.31964385086388, 47.62208618404055], floorNum: 2 },
  'E4:CE:66:9B:AC:7E': { coords: [-122.31975642720317, 47.62208646444484], floorNum: 2 },
  'F2:38:34:C0:ED:55': { coords: [-122.31974506042332, 47.62215538268214], floorNum: 2 },
  'F5:80:15:C1:BE:AD': { coords: [-122.31965023273585, 47.622163849358444], floorNum: 1 },
  'F4:B4:4F:6B:5E:9D': { coords: [-122.31974773470367, 47.62208853749835], floorNum: 1 },
  'F0:36:F2:8E:7C:80': { coords: [-122.31964053678112, 47.62208394016334], floorNum: 1 },
}

const fakeAlertBeacons = [
  {
    beaconId: 'E8:04:56:65:7D:45',
    rssi: -72,
  },
  {
    beaconId: 'F8:35:CC:74:8E:A6',
    rssi: -70,
  },
  {
    beaconId: 'C4:70:B4:51:34:CA',
    rssi: -90,
  },
  {
    beaconId: 'DD:C4:5B:48:85:F7',
    rssi: -94,
  },
]

const rileysAlertBeacons = [
  {
    beaconId: 'F0:36:F2:8E:7C:80',
    rssi: -71.0,
  },
  {
    beaconId: 'C5:D8:DC:8A:1D:C6',
    rssi: -73.0,
  },
  {
    beaconId: 'F4:B4:4F:6B:5E:9D',
    rssi: -79.0,
  },
  {
    beaconId: 'F2:38:34:C0:ED:55',
    rssi: -81.0,
  },
  {
    beaconId: 'D0:22:9F:77:BA:B5',
    rssi: -82.0,
  },
]

// interface Beacon {
//   beaconId: string;
//   rssi: number;
//   long: number | null;
//   lat: number | null;
//   alt: number | null;
// }

// interface Coordinates {
//   lat: number;
//   long: number;
//   alt: number;
// }

export function preProcess(beacons: any[], beaconCoordMap: any) {
  /* merges the beacons and the coordinates */
  const processed = beacons.map(b => {
    if (!(b.beaconId in beaconCoordMap)) return {}

    const coords = beaconCoordMap[b.beaconId].coords;
    return {
      ...b,
      long: Number(coords[0]),
      lat: Number(coords[1]),
      floorNum: Number(beaconCoordMap[b.beaconId].floorNum),
      roomHeight: Number(beaconCoordMap[b.beaconId].roomHeight) || 0
    }
  }).filter(b => b?.lat != null) // ensure lat is valid
  return processed
}

export function weightedAvgTrilaterate(beacons: any[]) {
  // Filter out beacons without valid coordinates
  const validBeacons = beacons.filter(b => b?.long || b?.lat || b?.floorNum || b?.roomHeight)

  if (validBeacons.length < 3) {
    console.error('Not enough valid beacons for trilateration.')
    return null
  }

  // Sort beacons by strongest RSSI (closest)
  validBeacons.sort((a, b) => b.rssi - a.rssi)

  // Use the top N beacons (at least 3)
  const selectedBeacons = validBeacons.slice(0, Math.min(validBeacons.length, 7))

  return trilaterateGeneric(selectedBeacons)
}

function trilaterateGeneric(beacons: any[]) {
  console.log(`Running trilateration with ${beacons.length} beacons...`)

  // RSSI to distance conversion parameters
  const RSSI_REF = -60 // Reference RSSI at 1 meter
  const N = 2 // Propagation constant (environmental factor)

  const calculateDistance = (rssi: number ) => Math.pow(10, (RSSI_REF - rssi) / (10 * N))

  // Compute distances for each beacon
  const distances = beacons.map(b => calculateDistance(b.rssi))

  // Compute weighted averages
  let sumX = 0,
    sumY = 0,
    sumZ = 0,
    sumW = 0

  for (let i = 0; i < beacons.length; i++) {
    const { long, lat, floorNum } = beacons[i]
    const weight = 1 / distances[i] // Inverse of distance for weighting

    sumX += long * weight
    sumY += lat * weight
    sumZ += floorNum * weight
    sumW += weight
  }

  return {
    lat: sumY / sumW,
    long: sumX / sumW,
    floorNum: sumZ / sumW,
  }
}

// const processed = preProcess(alertEventOffice.attributes.beacons, beacon_coords)
// // console.log(weightedAvgTrilaterate(processed))

/***********  v2 ***********/
// import { create, all, ConfigOptions } from 'mathjs';

// const config: ConfigOptions = {
//   number: 'number', // This literal matches the expected type
//   precision: 64
// };

// const math = create(all, config);



const FLOOR_HEIGHT = 4
const PATH_LOSS_EXPONENT = 2.7

function rssiToDistance(rssi: number, rssiAtOneMeter = -60, pathLossExponent = PATH_LOSS_EXPONENT) {
  if (rssi > -1000)
    return Math.pow(10, (rssiAtOneMeter - rssi) / (10 * pathLossExponent))
  else if( rssi > -85) return Math.pow(10, (rssiAtOneMeter - rssi) / (10 * pathLossExponent*1.1))
  else return Math.pow(10, (rssiAtOneMeter - rssi) / (10 * pathLossExponent*1.2))
}

function floorNumToMeters(floorNum: number) {
  return floorNum * FLOOR_HEIGHT
}

// function measure(lat1: number, lon1: number, lat2: number, lon2: number) {
//   // generally used geo measurement function
//   const R = 6378.137 // Radius of earth in KM
//   const dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180
//   const dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180
//   const a =
//     Math.sin(dLat / 2) * Math.sin(dLat / 2) +
//     Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
//   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
//   const d = R * c
//   return d * 1000 // meters
// }

/* --- */

function convertBeaconsToMeters(beacons: any[], ref: any) {
  console.log('`tri-ref: ', ref)
  const latFactor = 111320 // meters per degree latitude
  const longFactor = 111320 * Math.cos((ref.lat * Math.PI) / 180) // adjusted for latitude
  return beacons.map(beacon => ({
    ...beacon,
    x: (beacon.long - ref.long) * longFactor,
    y: (beacon.lat - ref.lat) * latFactor,
    z: beacon.height - ref.height, // relative height
  }))
}

import * as numeric from 'numeric';

export function matrixTrilaterationM(beacons: any[]) {
  if (beacons.length < 4) {
    throw new Error('At least four beacons are required for 3D trilateration.');
  }

  const ref = beacons[0];
  let A: number[][] = [];
  let b: number[] = [];

  for (let i = 1; i < beacons.length; i++) {
    console.log('~trying matrix math');
    const beacon = beacons[i];
    const { x: xi, y: yi, z: zi, d: di } = beacon;
    const { x: x1, y: y1, z: z1, d: d1 } = ref;

    A.push([
      2 * (xi - x1),
      2 * (yi - y1),
      2 * (zi - z1),
    ]);

    const ki = xi ** 2 + yi ** 2 + zi ** 2;
    const k1 = x1 ** 2 + y1 ** 2 + z1 ** 2;
    b.push(d1 ** 2 - di ** 2 - k1 + ki);
  }

  try {
    const At = numeric.transpose(A);
    const AtA = numeric.dot(At, A);

    // Add regularization if needed to avoid singular matrix errors
    const regularization = numeric.mul(numeric.identity((AtA as number[][]).length), 1e-6);
    const AtA_pinv = numeric.inv(numeric.add(AtA as number[][], regularization as number[][]));

    const AtB = numeric.dot(At, b);
    let result = numeric.dot(AtA_pinv, AtB);

    // Ensure result is a standard array
    if (!Array.isArray(result)) {
      result = [result]; // Wrap scalar results
    }

    if (!AtA || !AtA_pinv || !AtB || !result) {
      throw new Error('Matrix computation resulted in undefined or null values.');
    }

    return {
      x: result[0] ?? 0,
      y: result[1] ?? 0,
      z: result[2] ?? 0,
    };
  } catch (error) {
    console.error('Error in matrix calculations:', error);
  }

  return { x: 0, y: 0, z: 0 };
}


// 4. Convert the local x,y (in meters) back to global lat/long.
function convertMetersToLatLong(ref: any, localX: number, localY: number) {
  const latFactor = 111320 // meters per degree latitude
  const longFactor = 111320 * Math.cos((ref.lat * Math.PI) / 180)
  return {
    lat: ref.lat + localY / latFactor,
    long: ref.long + localX / longFactor,
  }
}

// This function brings everything together, only expecting beacon array and beacon coordinate mapping
export function matrixTrilaterationFull(beacons: any[], beaconsCoords: any) {
  console.log(`~tri-error beacons in full`, beacons)
  // 0. Merge beacons with coords
  const processed = preProcess(beacons, beaconsCoords)

  // 1. Convert beacon height and distance into meters (no conversion to lat, long, degrees yet)
  const convertedBeacons = processed
    .map(beacon => ({
      ...beacon,
      height: floorNumToMeters(beacon.floorNum) + beacon.roomHeight, // height in meters
      d: rssiToDistance(beacon.rssi), // distance in meters
    }))
    .filter(beacon => !isNaN(beacon.height) && !isNaN(beacon.d))
    .sort((a, b) => b.rssi - a.rssi)
    .slice(0, 5)

  // 2. Convert global lat/long to local Cartesian coordinates (meters)
  //    Also subtract the reference beacon’s height so z becomes relative.
  const refBeacon = convertedBeacons[0]
  console.log('~tri-Reference Beacon:', refBeacon)
  const meterBeacons = convertBeaconsToMeters(convertedBeacons, refBeacon)

  // 3. Perform 3D trilateration in the local meter coordinate system.
  const meterResult = matrixTrilaterationM(meterBeacons)

  // 4. Convert the local x,y (in meters) back to global lat/long.
  const globalCoordinates = convertMetersToLatLong(refBeacon, meterResult.x as number, meterResult.y as number);

  // 5. Add back the reference height to the computed z for the actual height.
  const estimatedHeight = meterResult.z + refBeacon.height
  const estimatedPosition = {
    lat: globalCoordinates.lat,
    long: globalCoordinates.long,
    height: estimatedHeight, // in meters (should be in 0-12 m range)
    floorNum: estimatedHeight / FLOOR_HEIGHT, // expected to be between 0-3
  }

  return estimatedPosition
}
