/** @jsxImportSource @emotion/react */
import { keyframes } from '@emotion/react'

import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { useEffect, useState } from 'react'

import Tooltip from '@material-ui/core/Tooltip'
import VenueMap from './VenueMap'
import VenueMap2 from './VenueMap2'
import { AlertSession } from 'lib/types'

type Props = {
  visible: boolean
  mapAvailable: boolean
  map: string | null
  previousMap: string | null
  alertSession: AlertSession
}

const VenueDrawer = ({ mapAvailable, map, previousMap, visible, alertSession }: Props) => {
  const [mapOpen, setMapOpen] = useState(true)
  const [initialLoading, setInitialLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setMapOpen(true)
      setInitialLoading(false)
    }, 800)
  }, [])

  let drawerWidth = document.getElementById('AlertDetailDrawer')?.offsetWidth || 0
  let drawerLeftShift = Number(drawerWidth) - 800 || 0

  const openAnimation = keyframes({
    from: {
      right: `${drawerLeftShift}px`,
    },
    to: {
      right: drawerWidth,
    },
  })

  const closeAnimation = keyframes({
    from: {
      right: drawerWidth,
    },
    to: {
      right: `${drawerLeftShift}px`,
    },
  })

  return (
    <>
      {visible ? (
        <>
          <div
            className="mapContainer"
            css={{
              position: 'fixed',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
              right:
                mapAvailable && !initialLoading && mapOpen
                  ? drawerWidth
                  : mapAvailable && mapOpen
                  ? `${drawerLeftShift}px`
                  : mapAvailable && !mapOpen
                  ? `${drawerLeftShift}px`
                  : `${0}px`,
              animation:
                mapAvailable && !initialLoading ? `${mapOpen ? openAnimation : closeAnimation} .7s ease-out 1` : '',
              width: drawerWidth,
              height: '100vh',
              zIndex: -1,
            }}
          >
            {mapAvailable ? (
              <div
                className="LocationMap"
                css={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'left',
                  backgroundColor: 'white',
                  borderRadius: '20px',
                }}
              >
                {/* <img
                  style={{
                    borderRadius: '10px 0px 0px 20px',
                    zIndex: 'inherit',
                    width: '500px',
                    height: '500px',
                  }}
                  src={`data:image/png;base64, ${map || previousMap}`}
                  alt={`No Map`}
                /> */}
                {/* For now, only enable trilateration for Jeffs and Rileys house.. */}
                {[
                  '6e5a4fb3-d880-47c3-8be5-5b830ded5fdc',
                  '0f96d715-d982-405c-adc1-4a23065df912',
                  '041a5cfc-796a-406a-bcf7-ea1825f67e3b',
                ].includes(alertSession.orgId) ? (
                  <VenueMap2 alertSession={alertSession} />
                ) : (
                  <VenueMap alertSession={alertSession} />
                )}
                <div
                  css={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '25px',
                    height: '55px',
                    backgroundColor: 'white',
                    borderRadius: '40% 0% 0% 40%',
                    position: 'absolute',
                    left: '-25px',
                    zIndex: 100000,
                    '&:hover': {
                      opacity: '0.8',
                    },
                  }}
                  onClick={() => {
                    setMapOpen(!mapOpen)
                  }}
                >
                  {mapOpen ? <ChevronRightIcon css={{}} /> : <ChevronLeftIcon css={{}} />}
                </div>
              </div>
            ) : (
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '25px',
                  height: '55px',
                  backgroundColor: 'white',
                  borderRadius: '40% 0% 0% 40%',
                  position: 'absolute',
                  right: `${drawerWidth}px`,
                  zIndex: 100000,
                  opacity: '0.7',
                }}
              >
                <Tooltip title="Map not available">
                  <ChevronLeftIcon css={{}} />
                </Tooltip>
              </div>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default VenueDrawer
