import { DEVICE_TYPES, HealthLabelsKeys } from 'lib/constants'
import {
  Beacon,
  Device,
  Gateway,
  IdPair,
  IdToIdList,
  NameNumberValuePair,
  SortOption,
  SortOrder,
  trans,
  TranslationGroup,
  TranslationKey,
  ucfirst,
} from 'lib/types'
const { each } = require('art-comprehensions')

export function invertBinaryEnum(e: any) {
  return (e + 1) % 2
}

export function sumValuesReducer(a: NameNumberValuePair, b: NameNumberValuePair): NameNumberValuePair {
  return {
    name: 'total',
    value: a.value + b.value,
  }
}

export function createSortOption<T>(field: T, order: SortOrder, display: string): SortOption<T> {
  return {
    sortBy: {
      field,
      order,
    },
    display,
  }
}

const isString = (a: any) => typeof a === 'string'

export function simpleCompare(a: any, b: any) {
  if (isString(a) && isString(b)) return a.localeCompare(b)
  if (a > b) return
  if (a < b) return -1
  return 0
}

export function invertIfDescending(diff: number, sortOrder: SortOrder) {
  return sortOrder === SortOrder.Descending ? diff * -1 : diff
}

export function isWan(deviceType: string) {
  return deviceType === DEVICE_TYPES.WAN_BUTTON
}

export const createIdToIdList = (pairs: IdPair[]): IdToIdList => {
  let out: IdToIdList = {}
  pairs.forEach(([from, to]) => (out[from] = out[from] || []).push(to))
  return out
}

export function invertIdToIdList(parentToChildrenMap: IdToIdList): IdToIdList {
  const invertedList: IdToIdList = {}
  each(parentToChildrenMap, (childIds: string[], parentId: string) =>
    each(childIds, (childId: string) => {
      if (!invertedList[childId]) invertedList[childId] = []
      if (0 > invertedList[childId].indexOf(childId)) {
        invertedList[childId].push(parentId)
      }
    }),
  )
  return invertedList
}

export function nullEmptyString(s: string) {
  return s === '' ? null : s
}

export function scrollToTop() {
  document.body.scrollTop = 0 // For Safari
  document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
}

export const getHealth = (b: Beacon | Device | Gateway, isBeacon = false) => {
  switch (b?.health) {
    case 0:
      return HealthLabelsKeys.CRITICAL
    //return isBeacon ? HealthLabelsKeys.CRITICAL : HealthLabelsKeys.OFFLINE
    case 1:
      return HealthLabelsKeys.MODERATE
    case 2:
      return HealthLabelsKeys.HEALTHY
    default:
      return HealthLabelsKeys.UNKNOWN
  }
}

export function getDeviceTooltip(device: Device) {
  const translation: TranslationGroup = trans.merge(TranslationKey.HEALTH_TOOLTIPS)
  const warnings: TranslationGroup = trans.group(TranslationKey.HEALTH_WARNINGS)
  const tmp = (device.healthWarnings?.map(warning => warnings[warning]) || []).concat()
  if (tmp.length === 0) return translation[`devices_${HealthLabelsKeys.HEALTHY}`]
  let last = ''
  if (tmp.length > 1) {
    last = `, ${translation.and} ${tmp.pop()}`
  }
  return `${ucfirst(translation.device)} ${tmp.join(', ')}${last}`
}

export const isPositiveInteger = (x: string) => {
  // http://stackoverflow.com/a/1019526/11236
  return /^\d+$/.test(x)
}

// https://medium.com/geekculture/sorting-an-array-of-semantic-versions-in-typescript-55d65d411df2
export const compareSemanticVersions = (a: string, b: string) => {
  // 1. Split the strings into their parts.
  const a1 = a.split('.')
  const b1 = b.split('.')
  // 2. Contingency in case there's a 4th or 5th version
  const len = Math.min(a1.length, b1.length)
  // 3. Look through each version number and compare.
  for (let i = 0; i < len; i++) {
    const a2 = +a1[i] || 0
    const b2 = +b1[i] || 0

    if (a2 !== b2) {
      return a2 > b2 ? 1 : -1
    }
  }

  // 4. We hit this if the all checked versions so far are equal
  return b1.length - a1.length
}

export function isNumber(str: any): boolean {
  return /^-?\d*\.?\d+$/.test(str)
}

export function sumObjectValues(obj: Record<string, any> | null, targetKey?: string): number {
  if (obj == null) return 0
  if (targetKey && obj.hasOwnProperty(targetKey)) {
    const subObj = obj[targetKey]
    if (typeof subObj === 'object') {
      return Object.values(subObj).reduce((acc: number, val) => acc + (typeof val === 'number' ? val : 0), 0)
    }
  }

  return 0
}

export function capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}