/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react/macro'
import { GatewaysColumnKeys, HealthLabelsKeys } from 'lib/constants'
import _ from 'lodash'
import { fuzzySearch } from '@art-suite/art-fuzzy-search'
import { Firmware } from 'components/partials'
import {
  SortBy,
  SortOption,
  SortOrder,
  TableColumnConfig,
  Gateway,
  TranslationKey,
  TranslationGroup,
  trans,
  ucfirst,
} from 'lib/types'
import { createSortOption } from 'lib/utils/common'
import {
  HighlightOff as HighlightOffIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from '@material-ui/icons'
import { generateCSV } from 'lib/utils/csv'
import { Orgs } from 'models'
import { getBatteryIcon, batteryWrapper } from 'styles'
import { formatDistanceToNow, getHealth, parseTime } from 'lib/utils'
import { isValidVersion, normalizeVersion } from 'models/modelUtils'
import semver from 'semver'
import { getObjectsCSV } from 'models/api'

const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' })

export function getFilterOptions() {
  const translation: TranslationGroup = trans.group(TranslationKey.BEACONS_TABLE_VIEW)
  const health: TranslationGroup = trans.merge(TranslationKey.HEALTH_LABELS)
  // return ['all gateways', HealthLabelsKeys.HEALTHY, HealthLabelsKeys.MODERATE, HealthLabelsKeys.CRITICAL] // awaiting endpoint update
  return ['all gateways']
}

export function getFilterOptionKeys() {
  return [HealthLabelsKeys.UNKNOWN, HealthLabelsKeys.CRITICAL, HealthLabelsKeys.MODERATE, HealthLabelsKeys.HEALTHY]
}

// Todo - add health warnings to translations
export function getBeaconTooltip(gateway: Gateway) {
  const translation: TranslationGroup = trans.merge(TranslationKey.HEALTH_TOOLTIPS)
  const warnings: TranslationGroup = trans.group(TranslationKey.HEALTH_WARNINGS)

  const tmp = (gateway.healthWarnings?.map(warning => warnings[warning]) || []).concat()
  if (tmp.length === 0) return translation[`gateways_${HealthLabelsKeys.HEALTHY}`]
  let last = ''
  if (tmp.length > 1) {
    last = `, ${translation.and} ${tmp.pop()}`
  }
  return `${ucfirst(translation.beacon)} ${tmp.join(', ')}${last}`
}

function getGatewayHealth(gateway: Gateway) {
  return getHealth(gateway, true)
}

function invertIfDescending(diff: number, sortOrder: SortOrder) {
  return sortOrder === SortOrder.Descending ? diff * -1 : diff
}

export const getSortOptions: () => SortOption<GatewaysColumnKeys>[] = () => {
  const translation: TranslationGroup = trans.merge(TranslationKey.BEACONS_TABLE_VIEW)
  return _.flatten(
    _.map(
      _.filter(GatewaysColumnKeys, c => c !== GatewaysColumnKeys.EUI), // Exclude 'eui'
      c => [
        createSortOption(c, SortOrder.Descending, `${translation[c]} ↓`),
        createSortOption(c, SortOrder.Ascending, `${translation[c]} ↑`),
      ],
    ),
  )
}

export function getFilterBeaconComparison(gateway: Gateway, filterBy: string) {
  const opts = getFilterOptions()
  const keys = getFilterOptionKeys()
  if (!opts.includes(filterBy)) throw new Error()
  const idx = opts.indexOf(filterBy)
  return idx === 0 ? true : getGatewayHealth(gateway) === keys[idx]
}

export function filterBeaconByHealthLabel(gateways: Gateway[], healthLabel: string) {
  return gateways.filter(a => getGatewayHealth(a) === healthLabel)
}

export const getColumnConfigs: () => TableColumnConfig<GatewaysColumnKeys, Gateway>[] = () => {
  // const translation: TranslationGroup = trans.merge(TranslationKey.GATEWAYS_TABLE_VIEW)
  const healthLabelsTranslation: TranslationGroup = trans.merge(TranslationKey.HEALTH_LABELS)

  return [
    {
      header: GatewaysColumnKeys.Health,
      // label: translation[GatewaysColumnKeys.Health],
      label: 'Health',
      renderFn: (gateway: Gateway) => {
        const health = getGatewayHealth(gateway)
        let icon = null

        switch (health) {
          case HealthLabelsKeys.HEALTHY:
            icon = <CheckCircleOutlineIcon style={{ color: '#5FD078' }} />
            break
          case HealthLabelsKeys.MODERATE:
            icon = <RemoveCircleOutlineIcon style={{ color: '#E89F0B' }} />
            break
          case HealthLabelsKeys.OFFLINE:
          case HealthLabelsKeys.CRITICAL:
            icon = <HighlightOffIcon style={{ color: '#F44336' }} />
            break
          default:
            icon = '?'
            break
        }
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                paddingRight: '10px',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              {icon}
            </div>
            <div style={{ lineHeight: '10px' }}>{healthLabelsTranslation[health]}</div>
          </div>
        )
      },
    },
    {
      header: GatewaysColumnKeys.Name,
      // label: translation[GatewaysColumnKeys.Name],
      label: 'Name',
      renderFn: (gateway: Gateway) => gateway.name,
    },
    {
      header: GatewaysColumnKeys.EUI,
      label: 'EUI',
      renderFn: (gateway: Gateway) => gateway.eui,
    },
    {
      header: GatewaysColumnKeys.LastSeenAt,
      // label: translation[GatewaysColumnKeys.LastSeenAt],
      label: 'Last Seen At',
      renderFn: (gateway: Gateway) => formatDistanceToNow(gateway.lastSeenAt),
    },
  ]
}

export const handleDownloadCSV = (orgId: string) => {
  console.log('Needs to be hooked up')
  //getBeaconsCSV(orgId)
}
