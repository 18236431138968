import React, { useEffect, useState } from 'react'
// import { TGetVenueOptions, getVenueMaker, showVenue } from '@mappedin/mappedin-js'
import '@mappedin/mappedin-js/lib/index.css'
import { TCameraTarget, getMapData, show3dMap, Floor } from '@mappedin/mappedin-js'
import { PRIMARY_COLOR } from 'lib/constants'
import { AlertSession, Beacon } from 'lib/types'
import { useMappedInContext } from 'contexts/MappedInProvider'
import { useGlobal } from 'contexts/GlobalProvider'
import { matrixTrilaterationFull, preProcess, weightedAvgTrilaterate } from 'lib/utils/trilateration'

// Temporary - define beacon coordinate maps
type BeaconCoords = {
  [key: string]: {
    coords: number[]
    floorNum: number
    roomHeight: number
  }
}
const beacon_coords: BeaconCoords = {
  'F8:35:CC:74:8E:A6': { coords: [-105.13356570731779, 40.552194279240304], floorNum: 1, roomHeight: 1 },
  'C2:B9:06:08:BC:19': { coords: [-105.13364070731896, 40.55217206063131], floorNum: 1, roomHeight: 1 },
  'E2:ED:25:FA:15:1C': { coords: [-105.13373358989547, 40.552189520388055], floorNum: 1, roomHeight: 1 },
  'F0:EA:B1:20:BB:32': { coords: [-105.1336358764969, 40.55224813906885], floorNum: 1, roomHeight: 1 },
  'E8:04:56:65:7D:45': { coords: [-105.13356822247897, 40.552236636897334], floorNum: 1, roomHeight: 1 },
  'E0:96:0F:B5:F2:35': { coords: [-105.13357731571347, 40.55218541414222], floorNum: 0, roomHeight: 1 },
  'C1:26:54:27:B4:5C': { coords: [-105.13368749327725, 40.552245398136364], floorNum: 0, roomHeight: 1 },
  'E4:B2:C7:BF:1F:E9': { coords: [-105.13373154811791, 40.55221302597942], floorNum: 0, roomHeight: 1 },
  'F4:B4:67:52:FB:63': { coords: [-105.13372419080135, 40.552196390693744], floorNum: 0, roomHeight: 1 },
  'C6:FD:10:8C:C1:13': { coords: [-105.13362105816623, 40.552235560498374], floorNum: 0, roomHeight: 1 },
  'DD:C4:5B:48:85:F7': { coords: [-105.13357035649099, 40.552125146794], floorNum: 2, roomHeight: 1 },
  'C4:70:B4:51:34:CA': { coords: [-105.1335606939494, 40.552179124132714], floorNum: 2, roomHeight: 1 },
  'DC:33:C8:7B:FC:F4': { coords: [-105.13360107463569, 40.55218458237247], floorNum: 2, roomHeight: 1 },
  'EC:D8:35:36:BB:A0': { coords: [-105.13361460990245, 40.5522279467806], floorNum: 2, roomHeight: 1 },
  // Riley's beacons
  'D0:22:9F:77:BA:B5': { coords: [-122.31969118187307, 47.62215640493455], floorNum: 2, roomHeight: 1.5 },
  'C5:D8:DC:8A:1D:C6': { coords: [-122.31964385086388, 47.62208618404055], floorNum: 2, roomHeight: 2 },
  'E4:CE:66:9B:AC:7E': { coords: [-122.31975642720317, 47.62208646444484], floorNum: 2, roomHeight: 1 },
  'F2:38:34:C0:ED:55': { coords: [-122.31974506042332, 47.62215538268214], floorNum: 2, roomHeight: 1 },
  'F5:80:15:C1:BE:AD': { coords: [-122.31965023273585, 47.622163849358444], floorNum: 1, roomHeight: 1.5 },
  'F4:B4:4F:6B:5E:9D': { coords: [-122.31974773470367, 47.62208853749835], floorNum: 1, roomHeight: 1 },
  'F0:36:F2:8E:7C:80': { coords: [-122.31964053678112, 47.62208394016334], floorNum: 1, roomHeight: 2 },

  // Box Truck
  // 'F8:35:CC:74:8E:A6': { coords: [-87.40063470325205, 35.497398672951704], floorNum: 1, roomHeight: 1 },
  // 'E8:04:56:65:7D:45': { coords: [-87.40058577287749, 35.497416189490316], floorNum: 1, roomHeight: 1 },
  // 'DD:C4:5B:48:85:F7': { coords: [-87.40067941404556, 35.49741655721198], floorNum: 2, roomHeight: 1 },
  // 'C4:70:B4:51:34:CA': { coords: [-87.40062827958317, 35.497397864803546], floorNum: 2, roomHeight: 1 },
}

// floor mapping: elevationIdx: floorId
const jeffFloors: Record<number, string> = {
  0: 'm_c31482a9948cc422',
  1: 'm_18663e4d9e0bff84',
  2: 'm_f146c13c397b6c38',
}

const rileysFloors: Record<number, string> = {
  0: 'm_26b51add82530c40',
  1: 'm_dfb600e42550c21a',
  2: 'm_3b92387c7206898f',
}

const boxTruckFloors: Record<number, string> = {
  0: 'm_38b66358bf3d091a',
  1: 'm_38b66358bf3d091a',
  2: 'm_575ae97e821dc072',
}

type Props = {
  alertSession: AlertSession
}

const VenueMap2 = ({ alertSession }: Props) => {
  const [initialized, setInitialized] = useState(false)
  const [mapData, setMapData] = useState<any>()
  const [mapView, setMapView] = useState<any>(null)
  const [floor, setFloor] = useState<any>()
  const [floors, setFloors] = useState<any>([])
  const [clickedCoord, setClickedCoord] = useState<any>(null)
  const [lastLocation, setLastLocation] = useState<any>(null)
  const { selectedOrg } = useGlobal()

  const getDiscreteFloorId = (decifloorNum: number | undefined, floors: Floor[]) => {
    if (decifloorNum == null) return -99
    let discreteFloor = Math.round(decifloorNum)

    // handle edge cases where if the floor is over 2 or under 1. Just set to the endpoint
    if (discreteFloor < 0) discreteFloor = 0
    if (discreteFloor > 2) discreteFloor = 2

    const floorTri = floors.filter(f => {
      if (alertSession.orgId === '6e5a4fb3-d880-47c3-8be5-5b830ded5fdc') return f.id === jeffFloors[discreteFloor]
      else if (alertSession.orgId === '0f96d715-d982-405c-adc1-4a23065df912')
        return f.id === rileysFloors[discreteFloor]
      else if (alertSession.orgId === '041a5cfc-796a-406a-bcf7-ea1825f67e3b')
        return f.id === boxTruckFloors[discreteFloor]
    })
    console.log('~tri-floorTri:', floorTri)
    return floorTri[0].id
  }

  useEffect(() => {
    // console.log('~tri-lastBeacons:', alertSession.lastBeacons)
    const processed = preProcess(alertSession.lastBeacons || [], beacon_coords)
    const beacons = alertSession?.lastBeacons ?? []
    console.log('~tri-weighted:', weightedAvgTrilaterate(processed))
    // if (beacons.length >= 5) {
    //   console.log('~tri-matrix-all:', matrixTrilaterationFull(alertSession.lastBeacons || [], beacon_coords))
    //   console.log(
    //     '~tri-matrix-1:',
    //     matrixTrilaterationFull([beacons[0], beacons[1], beacons[2], beacons[3]] || [], beacon_coords),
    //   ) // 1
    //   console.log(
    //     '~tri-matrix-2:',
    //     matrixTrilaterationFull([beacons[0], beacons[1], beacons[2], beacons[4]] || [], beacon_coords),
    //   ) // 2
    //   console.log(
    //     '~tri-matrix-3:',
    //     matrixTrilaterationFull([beacons[0], beacons[1], beacons[3], beacons[4]] || [], beacon_coords),
    //   ) // 3
    //   console.log(
    //     '~tri-matrix-4:',
    //     matrixTrilaterationFull([beacons[0], beacons[2], beacons[3], beacons[4]] || [], beacon_coords),
    //   ) // 4
    //   console.log(
    //     '~tri-matrix-5:',
    //     matrixTrilaterationFull([beacons[1], beacons[2], beacons[3], beacons[4]] || [], beacon_coords),
    //   ) // 5
    //}
  }, [alertSession])

  useEffect(() => {
    async function init() {
      console.log('Running mi init')
      let options: any = null
      if (selectedOrg?.mappedin) {
        const { mapId, key, secret } = selectedOrg.mappedin
        options = { mapId, key, secret }
      }

      if (!options) {
        console.log('~no options - returning', options)
        return
      }

      const mapData = await getMapData(options)

      const floors = mapData.getByType('floor')
      console.log('~floors:', floors)

      const mapView = await show3dMap(document.getElementById('mappedin-map') as HTMLDivElement, mapData)
      mapView.expand() // show all floors

      const beacons = alertSession?.lastBeacons ?? []
      const processed = preProcess(alertSession.lastBeacons || [], beacon_coords)
      const weightedLocation = weightedAvgTrilaterate(processed)

      const matrixLocationAll = matrixTrilaterationFull(processed, beacon_coords)
      console.log('~tri-matrixLocationAll', matrixLocationAll)

      const markerTemplate = (letter: string, floorNum: number) => {
        return `<div style="
            display: flex; 
            flex-direction: column; 
            align-items: center; 
            font-size: 1rem; 
            font-weight: bold; 
            color: red;
            line-height: 1.5;
            text-align: center;
          ">
            ${letter}
            <span style="font-size: 0.5rem; color: black; margin-top: -4px;">
              ${floorNum.toFixed(1)}
            </span>
          </div>`
      }
      const weightedXLocation = {
        floorId: getDiscreteFloorId(weightedLocation?.floorNum, floors),
        latitude: weightedLocation?.lat,
        longitude: weightedLocation?.long,
        __type: 'coordinate',
      } as any

      const matrixXLocationAll = {
        floorId: getDiscreteFloorId(matrixLocationAll?.floorNum, floors),
        latitude: matrixLocationAll?.lat,
        longitude: matrixLocationAll?.long,
        __type: 'coordinate',
      } as any

      if (beacons.length >= 5) {
        console.log('~tri-beacons-5', [beacons[0], beacons[1], beacons[2], beacons[3], beacons[4]])
        const matrixLocation1 = matrixTrilaterationFull(
          [processed[0], processed[1], processed[2], processed[3]] || [],
          beacon_coords,
        )
        const matrixLocation2 = matrixTrilaterationFull(
          [processed[0], processed[1], processed[2], processed[4]] || [],
          beacon_coords,
        )
        const matrixLocation3 = matrixTrilaterationFull(
          [processed[0], processed[1], processed[3], processed[4]] || [],
          beacon_coords,
        )
        const matrixLocation4 = matrixTrilaterationFull(
          [processed[0], processed[2], processed[3], processed[4]] || [],
          beacon_coords,
        )
        const matrixLocation5 = matrixTrilaterationFull(
          [processed[1], processed[2], processed[3], processed[4]] || [],
          beacon_coords,
        )
        const matrixXLocation1 = {
          floorId: getDiscreteFloorId(matrixLocation1?.floorNum, floors),
          latitude: matrixLocation1?.lat,
          longitude: matrixLocation1?.long,
          __type: 'coordinate',
        } as any
        const matrixXLocation2 = {
          floorId: getDiscreteFloorId(matrixLocation2?.floorNum, floors),
          latitude: matrixLocation2?.lat,
          longitude: matrixLocation2?.long,
          __type: 'coordinate',
        } as any
        const matrixXLocation3 = {
          floorId: getDiscreteFloorId(matrixLocation3?.floorNum, floors),
          latitude: matrixLocation3?.lat,
          longitude: matrixLocation3?.long,
          __type: 'coordinate',
        } as any
        const matrixXLocation4 = {
          floorId: getDiscreteFloorId(matrixLocation4?.floorNum, floors),
          latitude: matrixLocation4?.lat,
          longitude: matrixLocation4?.long,
          __type: 'coordinate',
        } as any
        const matrixXLocation5 = {
          floorId: getDiscreteFloorId(matrixLocation5?.floorNum, floors),
          latitude: matrixLocation5?.lat,
          longitude: matrixLocation5?.long,
          __type: 'coordinate',
        } as any
        mapView.Markers.add(matrixXLocation1, markerTemplate('a', matrixLocation1.floorNum), {
          interactive: true,
          anchor: 'center',
          rank: 'always-visible',
        })
        mapView.Markers.add(matrixXLocation2, markerTemplate('b', matrixLocation2.floorNum), {
          interactive: true,
          anchor: 'center',
          rank: 'always-visible',
        })
        mapView.Markers.add(matrixXLocation3, markerTemplate('c', matrixLocation3.floorNum), {
          interactive: true,
          anchor: 'center',
          rank: 'always-visible',
        })
        mapView.Markers.add(matrixXLocation4, markerTemplate('d', matrixLocation4.floorNum), {
          interactive: true,
          anchor: 'center',
          rank: 'always-visible',
        })
        mapView.Markers.add(matrixXLocation5, markerTemplate('e', matrixLocation5.floorNum), {
          interactive: true,
          anchor: 'center',
          rank: 'always-visible',
        })
      }

      // Add marker for trilateration
      mapView.Markers.add(weightedXLocation, markerTemplate('W', weightedLocation?.floorNum || -99), {
        interactive: true,
        anchor: 'center',
        rank: 'always-visible',
      })

      mapView.Markers.add(matrixXLocationAll, markerTemplate('All', matrixLocationAll.floorNum), {
        interactive: true,
        anchor: 'center',
        rank: 'always-visible',
      })

      mapView.Labels.all()
      // Set each space to be interactive.
      mapData.getByType('space').forEach(space => {
        console.log({ space })
        mapView.updateState(space, {
          interactive: true,
        })
      })

      const defaultCameraPosition: TCameraTarget = {
        bearing: mapView.Camera.bearing,
        pitch: mapView.Camera.pitch,
        zoomLevel: 18.5,
        center: mapView.Camera.center,
      }

      mapView.Camera.set(defaultCameraPosition)

      // Set each space to be interactive and its hover color.
      mapData.getByType('space').forEach((space, i) => {
        mapView.updateState(space, {
          //   color: i % 2 == 1 ? 'red' : 'blue',
          interactive: true,
          hoverColor: '#D3D3D3',
        })
      })

      mapData.getByType('space').forEach((space, i) => {
        if (space.name == alertSession?.lastLocation?.room) {
          mapView.updateState(space, {
            //   color: i % 2 == 1 ? 'red' : 'blue',
            interactive: true,
            hoverColor: '#D3D3D3',
            color: PRIMARY_COLOR,
          })
        }
      })

      let focused: boolean = false

      // Act on the click event to focus on the Space that was clicked or reset the camera.
      mapView.on('click', async event => {
        console.log('clicked event:', event)
        setClickedCoord(event.coordinate)

        mapView.Camera.focusOn(event.spaces[0])
        focused = true
        // }
      })

      setLastLocation(alertSession?.lastLocation)
      setMapView(mapView)
    }

    const sameLocation =
      alertSession?.lastLocation?.floor == lastLocation?.floor && alertSession?.lastLocation?.room == lastLocation?.room

    // if (sameLocation) {
    //   return
    // }

    init()
    return () => {}
  }, [initialized, alertSession]) // Include venueOptions in the dependency array

  return <div style={{ width: '800px', height: '800px' }} id="mappedin-map" />
}

export default VenueMap2
