import { shallowEqual } from 'react-redux'
import { EffectCallback, memo, useEffect, useState } from 'react'
import isNil from 'lodash/isNil'
import { ActionButton, ViewWrapper, PageWrapper, ViewSpacerWrapper } from 'components/widgets'
import { ALL_ORGS_SELECTED, BeaconsColumnKeys, BEACONS_PATH } from 'lib/constants'
import { Beacons, NavState, DetailsDrawer, PopUpNotifications } from 'models'
import * as utils from './utils'
import { PreConfiguredTableView } from 'components/partials/TableViewV2'
import { SortOrder, TableState, TableToolEvent, Beacon, RouteParams, trans, TranslationGroup } from 'lib/types'
import { invertBinaryEnum } from 'lib/utils/common'
import { BeaconDetailDrawer } from 'components/partials/DrawerDetails/BeaconDetailDrawer'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'models/modelUtils'
import { RenderCounter } from 'components/widgets/RenderCounter'
import { load } from 'models/Beacons'

import { useBeacons } from 'contexts/BeaconsProvider'
import { getRequestV5 } from 'models/api/rest'
import { useGlobal } from 'contexts/GlobalProvider'

function BeaconsPage() {
  const common: TranslationGroup = trans.common()
  const history = useHistory()
  const { id } = useParams<RouteParams>()
  const query = useQuery()
  // const selectedOrgId = NavState.use(({ selectedOrgId }) => selectedOrgId)
  // const { beacons, beaconsById, beaconsByOrgId, initialLoading, pagination, loading } = Beacons.use(
  //   ({ beacons, beaconsById, beaconsByOrgId, initialLoading, pagination, loading }) => {
  //     return { beacons, beaconsById, beaconsByOrgId, initialLoading, pagination, loading }
  //   },
  //   shallowEqual,
  // )
  const { loading, setLoading, beacons, setBeacons, tableState, setTableState, pagination, setPagination } =
    useBeacons()
  const { selectedOrgId, selectedOrg } = useGlobal()

  function handleDrawerClose() {
    history.push({
      pathname: `${BEACONS_PATH}`,
      search: query.toString(),
    })
    DetailsDrawer.close()
  }

  function handleRowClick(row: Beacon) {
    console.log({ row })
    if (row.id != id) {
      history.push({
        pathname: `${BEACONS_PATH}/${row.id}`,
        search: query.toString(),
      })
    }
    DetailsDrawer.show({
      drawerComponent: BeaconDetailDrawer,
      drawerProps: {
        beacon: row,
        close: handleDrawerClose,
      },
    })
  }

  function handleHeaderClick(header: string) {
    console.log({ header })
    // I will need to ignore certain header clicks as sorting is missing for some columns
    const excludeList = ['firmware']
    if (excludeList.includes(header)) return

    setLoading(true)
    getRequestV5({
      endpoint: '/beacons',
      headers: selectedOrgId && selectedOrgId != ALL_ORGS_SELECTED ? { org_id: selectedOrgId } : {},
      params: {
        filterBy:
          tableState.filterBy != utils.getFilterOptions()[0] // not all beacons
            ? { field: 'beaconType', value: tableState.filterBy.toUpperCase() }
            : undefined,
        searchBy: { value: tableState.searchBy },
        sortBy: {
          field: header,
          order: tableState.sortBy.order === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending,
        },
      },
    }).then(response => {
      const data = response.data
      setBeacons(data.data)
      setPagination(data.meta)
      setLoading(false)
      // setTableTools({ ...tableTools, pagination: data.meta })
    })
    setTableState(state => {
      return Object.assign({}, state, {
        sortBy: {
          field: header,
          order: state.sortBy.field === header ? invertBinaryEnum(state.sortBy.order) : SortOrder.Ascending,
        },
      })
    })
  }

  function handleToolEvent(toolEvent: TableToolEvent<string, BeaconsColumnKeys>) {
    console.log({ toolEvent })
    if (toolEvent.filterByRequest) {
      setLoading(true)
      getRequestV5({
        endpoint: '/beacons',
        headers: selectedOrgId && selectedOrgId != ALL_ORGS_SELECTED ? { org_id: selectedOrgId } : {},
        params: {
          filterBy:
            toolEvent.filterByRequest != utils.getFilterOptions()[0] // not all beacons
              ? { field: 'beaconType', value: toolEvent.filterByRequest.toUpperCase() }
              : undefined,
          searchBy: { value: tableState.searchBy },
        },
      }).then(response => {
        const data = response.data
        setBeacons(data.data)
        setPagination(data.meta)
        setLoading(false)
      })
    } else if (toolEvent.searchByRequest || toolEvent.searchByRequest == '') {
      setLoading(true)
      getRequestV5({
        endpoint: '/beacons',
        headers: selectedOrgId && selectedOrgId != ALL_ORGS_SELECTED ? { org_id: selectedOrgId } : {},
        params: {
          filterBy:
            tableState.filterBy != utils.getFilterOptions()[0] // not all beacons
              ? { field: 'beaconType', value: tableState.filterBy.toUpperCase() }
              : undefined,
          searchBy: { value: toolEvent.searchByRequest },
        },
      }).then(response => {
        const data = response.data
        setBeacons(data.data)
        setPagination(data.meta)
        setLoading(false)
        // setTableTools({ ...tableTools, pagination: data.meta })
      })
    } else if (toolEvent.sortByRequest) {
      console.log('so', toolEvent.sortByRequest)

      setLoading(true)
      getRequestV5({
        endpoint: '/beacons',
        headers: selectedOrgId && selectedOrgId != ALL_ORGS_SELECTED ? { org_id: selectedOrgId } : {},
        params: {
          filterBy:
            tableState.filterBy != utils.getFilterOptions()[0] // not all beacons
              ? { field: 'beaconType', value: tableState.filterBy.toUpperCase() }
              : undefined,
          searchBy: { value: tableState.searchBy },
          sortBy: { field: toolEvent.sortByRequest.field, order: toolEvent.sortByRequest.order },
        },
      }).then(response => {
        const data = response.data
        setBeacons(data.data)
        setPagination(data.meta)
        setLoading(false)
        // setTableTools({ ...tableTools, pagination: data.meta })
      })
    }

    // set table state after tool event
    setTableState(state => ({
      filterBy: toolEvent.filterByRequest || state.filterBy,
      sortBy: toolEvent.sortByRequest || state.sortBy,
      searchBy: isNil(toolEvent.searchByRequest) ? state.searchBy : toolEvent.searchByRequest,
    }))
  }

  function handlePageChange(page: any, newPage: any) {
    console.log('page:', page, 'np:', newPage)
    setLoading(true)
    getRequestV5({
      endpoint: '/beacons',
      headers: selectedOrgId && selectedOrgId != ALL_ORGS_SELECTED ? { org_id: selectedOrgId } : {},
      params: {
        pagination: { currentPage: newPage },
        filterBy:
          tableState.filterBy != utils.getFilterOptions()[0] // all beacons selected in filter?
            ? { field: 'beaconType', value: tableState.filterBy.toUpperCase() }
            : undefined,
        searchBy: { value: tableState.searchBy },
        sortBy: {
          field: tableState.sortBy.field,
          order: tableState.sortBy.order,
        },
      },
    }).then(response => {
      const data = response.data
      setBeacons(data.data)
      setPagination(data.meta)
      setLoading(false)
    })
  }

  return (
    <PageWrapper>
      <ViewWrapper>
        <ViewSpacerWrapper>
          <RenderCounter name={'BeaconsPage'} />
          <PreConfiguredTableView
            sortedData={beacons}
            tableState={tableState}
            columnConfigs={utils.getColumnConfigs()}
            filterOptions={utils.getFilterOptions()}
            sortOptions={utils.getSortOptions()}
            getRowToolTip={utils.getBeaconTooltip}
            onHeaderClick={handleHeaderClick}
            onRowClick={handleRowClick}
            onToolEvent={handleToolEvent}
            selectedRowId={id}
            loading={loading}
            pagination={pagination}
            onPageChange={handlePageChange}
          >
            {selectedOrgId && selectedOrgId !== ALL_ORGS_SELECTED && selectedOrg?.orgType !== 'group' && (
              <ActionButton onClick={() => utils.handleDownloadCSV(selectedOrgId || '')}>
                {common.download_csv}
              </ActionButton>
            )}
          </PreConfiguredTableView>
        </ViewSpacerWrapper>
      </ViewWrapper>
    </PageWrapper>
  )
}
export default memo(BeaconsPage)
