/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react/macro'
import { BeaconsColumnKeys, HealthLabelsKeys } from 'lib/constants'
import _ from 'lodash'
import { fuzzySearch } from '@art-suite/art-fuzzy-search'
import { Firmware } from 'components/partials'
import {
  SortBy,
  SortOption,
  SortOrder,
  TableColumnConfig,
  Beacon,
  TranslationKey,
  TranslationGroup,
  trans,
  ucfirst,
  BeaconType,
} from 'lib/types'
import { createSortOption } from 'lib/utils/common'
import {
  HighlightOff as HighlightOffIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from '@material-ui/icons'
import { generateCSV } from 'lib/utils/csv'
import { Orgs } from 'models'
import { getBatteryIcon, batteryWrapper } from 'styles'
import { formatDistanceToNow, getHealth, parseTime } from 'lib/utils'
import { isValidVersion, normalizeVersion } from 'models/modelUtils'
import semver from 'semver'
import { getBeaconsCSV, getObjectsCSV, getV5BeaconsCSV } from 'models/api'

const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' })

export function getFilterOptions() {
  const translation: TranslationGroup = trans.group(TranslationKey.BEACONS_TABLE_VIEW)
  const health: TranslationGroup = trans.merge(TranslationKey.HEALTH_LABELS)
  return [
    translation.all_beacons,
    BeaconType.ESTIMOTE,
    BeaconType.IBEACON,
    BeaconType.REACT,
    BeaconType.REACT2,
    BeaconType.CARFIT,
  ]
}

export function getFilterOptionKeys() {
  return [HealthLabelsKeys.UNKNOWN, HealthLabelsKeys.CRITICAL, HealthLabelsKeys.MODERATE, HealthLabelsKeys.HEALTHY]
}

export function getBeaconTooltip(beacon: Beacon) {
  const translation: TranslationGroup = trans.merge(TranslationKey.HEALTH_TOOLTIPS)
  const warnings: TranslationGroup = trans.group(TranslationKey.HEALTH_WARNINGS)

  const tmp = (beacon.healthWarnings?.map(warning => warnings[warning]) || []).concat()
  if (tmp.length === 0) return translation[`beacons_${HealthLabelsKeys.HEALTHY}`]
  let last = ''
  if (tmp.length > 1) {
    last = `, ${translation.and} ${tmp.pop()}`
  }
  return `${ucfirst(translation.beacon)} ${tmp.join(', ')}${last}`
}

function getBeaconHealth(beacon: Beacon) {
  return getHealth(beacon, true)
}

function invertIfDescending(diff: number, sortOrder: SortOrder) {
  return sortOrder === SortOrder.Descending ? diff * -1 : diff
}

export const getSortOptions: () => SortOption<BeaconsColumnKeys>[] = () => {
  const translation: TranslationGroup = trans.merge(TranslationKey.BEACONS_TABLE_VIEW)
  // console.log({ translation })
  // console.log(translation[BeaconsColumnKeys.Type])
  return _.flatten(
    _.map(
      // _.filter(BeaconsColumnKeys, c => c !== BeaconsColumnKeys.Health), // Exclude 'health' key
      BeaconsColumnKeys,
      c => [
        createSortOption(c, SortOrder.Descending, `${translation[c]} ↓`),
        createSortOption(c, SortOrder.Ascending, `${translation[c]} ↑`),
      ],
    ),
  )
}

export function searchBeacons(beacons: Beacon[], searchBy: string): Beacon[] {
  // This function is very expensive, only execute if necessary
  // TODO: Look for ways to improve efficiency
  if (searchBy.length < 2) return beacons
  const searchArray = _.flatten(
    beacons.map(beacon => {
      const arr = [
        [getBeaconHealth(beacon), beacon.id],
        [beacon.beaconType, beacon.id],
        [beacon.floor, beacon.id],
        [beacon.room, beacon.id],
        [beacon.name, beacon.id],
      ]
      const tmp = new Set()
      Object.entries(beacon.props?.version || {}).forEach(([key, value]) => {
        if (isValidVersion(value)) tmp.add(value)
      })
      if (tmp.size) arr.push([Array.from(tmp).join(', '), beacon.id])
      return arr
    }),
  )

  const searchResults = fuzzySearch(searchBy, searchArray)
  const searchResultsIds = searchResults.map(result => result[1] || null).filter(result => result !== null)
  return beacons.filter(beacon => searchResultsIds.includes(beacon.id))
}

export function getFilterBeaconComparison(beacon: Beacon, filterBy: string) {
  const opts = getFilterOptions()
  const keys = getFilterOptionKeys()
  if (!opts.includes(filterBy)) throw new Error()
  const idx = opts.indexOf(filterBy)
  return idx === 0 ? true : getBeaconHealth(beacon) === keys[idx]
}

export function filterBeaconByHealthLabel(beacons: Beacon[], healthLabel: string) {
  return beacons.filter(a => getBeaconHealth(a) === healthLabel)
}

export const getColumnConfigs: () => TableColumnConfig<BeaconsColumnKeys, Beacon>[] = () => {
  const translation: TranslationGroup = trans.merge(TranslationKey.BEACONS_TABLE_VIEW)
  const healthLabelsTranslation: TranslationGroup = trans.merge(TranslationKey.HEALTH_LABELS)

  return [
    {
      header: BeaconsColumnKeys.Health,
      label: translation[BeaconsColumnKeys.Health],
      renderFn: (beacon: Beacon) => {
        const health = getBeaconHealth(beacon)
        let icon = null

        switch (health) {
          case HealthLabelsKeys.HEALTHY:
            icon = <CheckCircleOutlineIcon style={{ color: '#5FD078' }} />
            break
          case HealthLabelsKeys.MODERATE:
            icon = <RemoveCircleOutlineIcon style={{ color: '#E89F0B' }} />
            break
          case HealthLabelsKeys.OFFLINE:
          case HealthLabelsKeys.CRITICAL:
            icon = <HighlightOffIcon style={{ color: '#F44336' }} />
            break
          default:
            icon = '?'
            break
        }
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                paddingRight: '10px',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              {icon}
            </div>
            <div style={{ lineHeight: '10px' }}>{healthLabelsTranslation[health]}</div>
          </div>
        )
      },
    },
    {
      header: BeaconsColumnKeys.Battery,
      label: translation[BeaconsColumnKeys.Battery],
      renderFn: (beacon: Beacon) => {
        if (beacon.batteryPercent === 0) return <></>
        return (
          <div css={batteryWrapper}>
            {getBatteryIcon(beacon.batteryPercent)}
            {!_.isNil(beacon.batteryPercent) && `${beacon.batteryPercent}%`}
          </div>
        )
      },
    },
    {
      header: BeaconsColumnKeys.Firmware,
      label: translation[BeaconsColumnKeys.Firmware],
      renderFn: (beacon: Beacon) => (
        <Firmware update={beacon.updateFirmware} fallback={''} version={beacon.props?.version} />
      ),
    },
    {
      header: BeaconsColumnKeys.Type,
      label: translation[BeaconsColumnKeys.Type],
      renderFn: (beacon: Beacon) => beacon.beaconType,
    },
    {
      header: BeaconsColumnKeys.Floor,
      label: translation[BeaconsColumnKeys.Floor],
      renderFn: (beacon: Beacon) => beacon.floor,
    },
    {
      header: BeaconsColumnKeys.Room,
      label: translation[BeaconsColumnKeys.Room],
      renderFn: (beacon: Beacon) => beacon.room,
    },
    {
      header: BeaconsColumnKeys.Name,
      label: translation[BeaconsColumnKeys.Name],
      renderFn: (beacon: Beacon) => beacon.name,
    },
    {
      header: BeaconsColumnKeys.LastSeenAt,
      label: translation[BeaconsColumnKeys.LastSeenAt],
      renderFn: (beacon: Beacon) => formatDistanceToNow(beacon.lastSeenAt),
    },
  ]
}

export function getSortBeaconCompareFn(sortBy: SortBy<BeaconsColumnKeys>): (a: Beacon, b: Beacon) => number {
  const keys = getFilterOptionKeys()
  switch (sortBy.field) {
    case BeaconsColumnKeys.Health:
      return (a: Beacon, b: Beacon) => {
        const diff = keys.findIndex(h => getBeaconHealth(a) === h) - keys.findIndex(h => getBeaconHealth(b) === h)
        return normalizeCompareResult(invertIfDescending(diff, sortBy.order))
      }

    case BeaconsColumnKeys.Type:
      return (a: Beacon, b: Beacon) =>
        normalizeCompareResult(invertIfDescending(simpleSort(a.beaconType, b.beaconType), sortBy.order))

    case BeaconsColumnKeys.Floor:
      return (a: Beacon, b: Beacon) =>
        sortBy.order === SortOrder.Ascending ? collator.compare(a.floor, b.floor) : collator.compare(b.floor, a.floor)

    case BeaconsColumnKeys.Room:
      return (a: Beacon, b: Beacon) =>
        sortBy.order === SortOrder.Ascending ? collator.compare(a.room, b.room) : collator.compare(b.room, a.room)

    case BeaconsColumnKeys.Name:
      return (a: Beacon, b: Beacon) =>
        sortBy.order === SortOrder.Ascending ? collator.compare(a.name, b.name) : collator.compare(b.name, a.name)

    case BeaconsColumnKeys.Battery:
      return (a: Beacon, b: Beacon) =>
        normalizeCompareResult(invertIfDescending(simpleSort(a.batteryPercent, b.batteryPercent), sortBy.order))

    case BeaconsColumnKeys.Firmware:
      return (a: Beacon, b: Beacon) => {
        const firmwareA = Object.keys(a.props?.version || {})?.[0]
        const firmwareB = Object.keys(b.props?.version || {})?.[0]
        const versionA = normalizeVersion(a.props?.version?.[firmwareA], '0.0.0')
        const versionB = normalizeVersion(b.props?.version?.[firmwareB], '0.0.0')

        return invertIfDescending(semver.compare(versionA, versionB), sortBy.order)
      }

    case BeaconsColumnKeys.LastSeenAt:
      return (a: Beacon, b: Beacon) =>
        normalizeCompareResult(invertIfDescending(simpleSort(a.updatedAt, b.updatedAt), sortBy.order))

    default:
      console.warn(`Unhandled sort column ${sortBy.field}`)
      return () => 0
  }
}

function simpleSort(a: any, b: any) {
  if (a > b) return 1
  if (a < b) return -1
  return 0
}

function normalizeCompareResult(r: number) {
  return simpleSort(r, 0)
}

export function downloadCsv(beacons: Beacon[]) {
  const translation: TranslationGroup = trans.merge(TranslationKey.BEACONS_TABLE_VIEW)

  const fields = [
    translation.health,
    translation.battery,
    translation.firmware,
    translation.type,
    translation.name,
    translation.location,
    translation.room,
    translation.floor,
    translation.last_check_in,
  ]
  const data = beacons.map(beacon => [
    getBeaconHealth(beacon),
    beacon.batteryPercent,
    beacon.props.version ? JSON.stringify(beacon.props.version) : '-',
    beacon.beaconType,
    beacon.name,
    `${translation.room}: ${beacon.room} - ${translation.floor} ${beacon.floor}`,
    beacon.room,
    beacon.floor,
    parseTime(beacon.updatedAt, true).toISOString(),
  ])

  const name = `${Orgs.getSelectedOrgName()} ${translation.beacons}`.trim()

  generateCSV(data, fields, name)
}

export const handleDownloadCSV = (orgId: string) => {
  getV5BeaconsCSV(orgId)
}
