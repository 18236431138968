/** @jsxImportSource @emotion/react */
import {
  Device,
  FirmwareVersionProps,
  Gateway,
  RegisterStatus,
  trans,
  TranslationGroup,
  TranslationKey,
} from 'lib/types'
import { useState } from 'react'
import { DetailSliderContent, EditElementModal, ConfirmationDialog } from 'components/partials'
import { getBatteryDisplay, getHealthIcon } from 'components/partial-pages/ButtonsDevicesPage'
import useModal from 'lib/hooks/useModal'
import { ActionButton } from 'components/widgets'
import { ButtonDetail, DetailRow, FirmwareDetail, WanDetail } from './utils'
import { getHealth, isWan, parseTime, toDate } from 'lib/utils'
import { format } from 'date-fns'
import { Devices, Orgs } from 'models'
import { isAdmin, isInstaller } from 'lib/utils/auth'
import { actionRowStyles, detailStyles } from 'styles'
import { unregisterAlertSource, updateAlertSource, disableAlertSource } from 'models/api'
import { useGlobal } from 'contexts/GlobalProvider'
import { ALL_ORGS_SELECTED } from 'lib/constants'

export const GatewayDetailDrawer = ({ gateway, close }: { gateway: Gateway; close: Function }) => {
  const translation: TranslationGroup = trans.merge(TranslationKey.DEVICES_DRAWER)
  const healthLabels: TranslationGroup = trans.merge(TranslationKey.HEALTH_LABELS)

  // const { Modal: ModalEdit, openModal: openModalEdit, closeModal: closeModalEdit } = useModal(translation.Edit_Device)
  const gatewayOrgId = gateway.orgId
  const orgsById = Orgs.use(({ orgsById }) => orgsById)
  const org = orgsById[gatewayOrgId]
  const { selectedOrgId, setRefreshFlag } = useGlobal()

  const rightStyles = {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '1.0rem',
  }

  const padRightStyles = {
    paddingRight: '10px',
  }

  const batteryWrapperStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: '64px',
  }
  const health = getHealth(gateway)
  const healthRow = (
    <div css={rightStyles}>
      <div css={padRightStyles}>{getHealthIcon(health)}</div>
      <div>{healthLabels[health]}</div>
    </div>
  )

  const preciseDate = gateway.lastSeenAt ? format(toDate(parseTime(gateway.lastSeenAt)), 'M/d/yyyy pp') : ''

  return (
    <>
      <DetailSliderContent title={'Gateway' + ' ' + translation.Details} close={close}>
        <ul css={detailStyles}>
          {org && <DetailRow title={translation.Property} content={org.name} />}
          <DetailRow title={translation.Property_ID} content={gatewayOrgId} />
          <DetailRow title={translation.Health} content={healthRow} />
          <DetailRow title={translation.Type} content={gateway.gatewayType} />
          <DetailRow title={translation.Name} content={gateway.name} />
          <DetailRow title={'eui'} content={gateway.eui} />
          <DetailRow title={translation.Last_Check_In} content={preciseDate} />
        </ul>
      </DetailSliderContent>
    </>
  )
}
